.all-block {
  display: flex;
  margin-left: 67px;
  border-radius: 40px;
  background: #d5dee5;
  margin-bottom: 50px;
}
.block {
  height: 200px;
  width: 246px;
}
.block:nth-of-type(3) {
  background: linear-gradient(45deg, #0c4362, #092235);
  transform: skewX(-26deg);
  margin-left: 50px;
  position: relative;
}
.block:nth-of-type(4) {
  background: linear-gradient(45deg, #0b6634, #05241f);
  transform: skewX(-26deg);
  position: relative;
  z-index: 1;
}
.block:nth-of-type(5) {
  position: relative;
}
.block:nth-of-type(3) div,
.block:nth-of-type(4) div {
  transform: skewX(26deg);
  color: #fff;
}
.block:nth-of-type(3) div p,
.block:nth-of-type(4) div p {
  transform: translateX(-20px);
}
.block p {
  font-weight: 500;
}
.block div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  height: 100%;
  color: #000;
}
.block .color-one {
  transform: skewX(-26deg);
  width: 70px;
  background: linear-gradient(to bottom, #07b15e, #08b459);
  position: absolute;
}
.block .color-one:before {
  content: "";
  position: absolute;
  width: 70px;
  height: 65rem;
  bottom: 0;
  background: #07b15e;
}
.block .color-two {
  background: linear-gradient(#106a71, #0f6b71);
  width: 100%;
  border-bottom-right-radius: 40px;
}
.block .color-two::before {
  content: "";
  position: absolute;
  top: -269px;
  right: -1px;
  transform: skewX(-0.5deg);
  background: transparent;
  border-bottom: 270px solid #0f6b71;
  z-index: -1;
  border-left: 129px solid transparent;
}
html {
  overflow-x: hidden;
}
.wwd-title {
  text-align: center;
  font-weight: 400;
  margin: 30px 0 80px 0;
}
.block-image {
  width: 65px;
  height: 65px;
}
@media screen and (max-width: 767px) {
  .about-bottom-card {
    flex-direction: column;
  }
  .about-bottom-content {
    width: 100% !important;
    text-align: center;
    margin-top: 20px;
  }
}
@media screen and (min-width: 1230px) {
  .block {
    width: 20%;
  }
  .block .color-one:before {
    height: 96rem;
  }
  .block .color-two:before {
    background: transparent;
    border-bottom: 491px solid #0f6b71;
    border-left: 183px solid #0f6b71;
    content: "";
    position: absolute;
    border-right: 65px solid #0f6b71;
    right: -129px;
    top: -490px;
    transform: skewX(334deg);
  }
}
