.sticky-menu {
  position: fixed;
  top: calc(50% - 75px);
  left: 0;
  z-index: 99;
  height: 50px;
  width: 145px;
}
.sticky-menu ul,
.sticky-menu ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.sticky-menu ul {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0;
}
.sticky-menu ul li {
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  height: 50px;
  margin-bottom: 6px;
  border-radius: 0 60px 60px 0;
  -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
}
.sticky-menu ul li a {
  color: #373737;
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 600;
  line-height: 14px;
  text-align: left;
  display: block;
  width: 140px;
  padding-left: 44px;
  opacity: 1;
  text-decoration: none;
  overflow: hidden;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.sticky-menu ul li a:hover {
  opacity: 0.8;
}
.sticky-menu ul li a:before {
  content: "";
  position: absolute;
  width: 32px;
  height: 32px;
  left: 7px;
  top: calc(50% - 16px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.particuliers-theme .sticky-menu ul li a.others:before,
.sticky-menu ul li a.others:before {
  background-image: url("../../public/assets/images/icons/servicee.png");
}
.particuliers-theme .sticky-menu ul li a.aboutus:before,
.sticky-menu ul li a.aboutus:before {
  background-image: url("../../public/assets/images/icons/aboutus.png");
}
.particuliers-theme .sticky-menu ul li a.contactus:before,
.sticky-menu ul li a.contactus:before {
  background-image: url("../../public/assets/images/icons/contactUs.png");
}
@media (min-width: 992px) and (max-width: 1920px) {
  .sticky-menu {
    width: auto;
  }
  .sticky-menu ul li a {
    width: 44px;
  }
  .sticky-menu ul li a:before {
    left: 4px;
  }
  .sticky-menu ul li a span {
    opacity: 0;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    white-space: nowrap;
  }
  .sticky-menu ul li a:hover {
    width: 140px;
  }
  .sticky-menu ul li a:hover span {
    opacity: 1;
  }
}
@media (max-width: 991.98px) {
  .sticky-menu {
    width: 100%;
    top: auto;
    bottom: 0;
    left: 0;
    -webkit-transition: bottom 0.4s ease-in-out;
    transition: bottom 0.4s ease-in-out;
    height: 60px;
  }
  .sticky-menu.nav-up {
    top: -60px;
  }
  .sticky-menu ul {
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .sticky-menu ul li {
    width: 30%;
    border-radius: 0;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    margin: 0 5px;
    height: 60px;
    position: relative;
    -webkit-box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.16);
  }
  .sticky-menu ul li:first-child {
    margin-left: 8px;
  }
  .sticky-menu ul li:last-child {
    margin-right: 8px;
  }
  .sticky-menu ul li a {
    width: 100%;
    padding: 38px 10px 0;
    position: initial;
    text-align: center;
  }
  .sticky-menu ul li a:before {
    width: 26px;
    height: 26px;
    left: calc(50% - 13px);
    top: 11px;
  }
}
/* .business-theme .sticky-menu ul li a.convertisseur:before {
    background-image: url("https://img.icons8.com/color/64/000000/service.png");
}
.business-theme .sticky-menu ul li a.simulateur:before {
    background-image: url("https://img.icons8.com/ios/50/000000/about.png");
}
.business-theme .sticky-menu ul li a.nos-agences:before {
    background-image: url("https://img.icons8.com/external-itim2101-lineal-itim2101/64/000000/external-contact-us-contact-and-message-itim2101-lineal-itim2101-4.png");
} */
