.faq-block {
    background: #edeff1;
    padding: 4.25rem 0;
}
.ui-widget {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
}
.ui-helper-reset {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    line-height: 1.3;
    text-decoration: none;
    font-size: 100%;
    list-style: none;
}
.faq-block .ui-accordion-header-collapsed {
    margin-bottom: 0.625rem;
}
.faq-block .ui-state-default {
    padding: 2.5rem;
}
.faq-block .ui-state-active,
.faq-block .ui-state-default {
    color: #373737;
    border: #fff;
    background: #fff;
}
.faq-block .ui-corner-all {
    border-radius: 0.5rem;
}
.ui-accordion .ui-accordion-header {
    display: block;
    cursor: pointer;
    position: relative;
    margin: 2px 0 0 0;
    padding: 1em 0.5em 1em 2em;
    font-size: 100%;
}
.faq-block p {
    line-height: 2.07;
    margin-top: 0;
    font-family: "Philisopher";
    font-size: 0.875rem;
}

.faq-block .ui-state-default .b-bt {
    padding-right: 1.5rem;
}
.faq-block .ui-state-active .b-bt {
    border-bottom: 2px solid rgba(0, 0, 0, 0.09);
    padding-bottom: 1.5rem;
}
.faq-block .ui-state-active,
.faq-block .ui-state-default {
    color: #373737;
    border: #fff;
    background: #fff;
}

.wrapper {
    margin: 0 auto;
    padding: 40px;
    max-width: 800px;
}

.table {
    margin: 0 0 40px 0;
    width: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    display: table;
}
@media screen and (max-width: 580px) {
    .table {
        display: block;
    }
}

.tableRow {
    display: table-row;
    background: #f6f6f6;
}
.tableRow:nth-of-type(odd) {
    background: #e9e9e9;
}
.tableRow.header {
    font-weight: 900;
    color: #ffffff;
}
.tableRow.green {
    background: #76a82f;
}

@media screen and (max-width: 580px) {
    .tableRow {
        padding: 14px 0 7px;
        display: block;
    }
    .tableRow.header {
        padding: 0;
        height: 6px;
    }
    .tableRow.header .cell {
        display: none;
    }
    .tableRow .cell {
        margin-bottom: 10px;
    }
    .tableRow .cell:before {
        margin-bottom: 3px;
        content: attr(data-title);
        min-width: 98px;
        font-size: 10px;
        line-height: 10px;
        font-weight: bold;
        text-transform: uppercase;
        color: #969696;
        display: block;
    }
}

.cell {
    padding: 6px 12px;
    display: table-cell;
}
@media screen and (max-width: 580px) {
    .cell {
        padding: 2px 16px;
        display: block;
    }
}
