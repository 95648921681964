.home-tabs {
  margin-top: 50px;
  /* margin-bottom: 150px; */
}
@media (max-width: 991.98px) {
  .home-tabs {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
.home-tabs .single-content {
  margin-top: 20px;
}
.home-tabs .owl-carousel .owl-item.active.center .p-links {
  border: 2px solid #76a82f;
}
.home-tabs .satoripop_tabs {
  max-width: 980px;
  width: 100%;
  margin: 50px auto;
  padding: 0;
  display: block;
  background-color: #fff;
  border-radius: 1.25rem;
  -webkit-box-shadow: 0 0.5rem 1rem rgba(55, 55, 55, 0.15);
  box-shadow: 0 0.5rem 1rem rgba(55, 55, 55, 0.15);
}
@media (max-width: 991.98px) {
  .home-tabs .satoripop_tabs {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
.home-tabs .ui-tabs .ui-tabs-nav {
  margin: 0;
  padding: 15px 23px 0;
  border-top-right-radius: 1.25rem;
  border-top-left-radius: 1.25rem;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  clear: both;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  border: 0;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.home-tabs .ui-tabs .ui-tabs-nav li,
.home-tabs .ui-widget-header .ui-state-default a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.home-tabs .ui-tabs .ui-tabs-nav li {
  white-space: inherit;
  width: 150px;
  word-break: break-all;
  float: none;
  min-height: 92px;
}
@media (min-width: 768px) {
  .home-tabs .ui-tabs .ui-tabs-nav li {
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    border: solid 1px rgba(144, 144, 144, 0.22);
    border-bottom: none;
  }
}
.home-tabs .ui-tabs .ui-tabs-nav li {
  text-align: center;
  padding-left: 4px;
  padding-right: 4px;
}
.home-tabs .ui-widget.ui-widget-content {
  border: 0;
}
.home-tabs .ui-widget-header .ui-state-default {
  height: 100%;
  background: 0 0;
  border: 0;
}
.home-tabs .ui-widget-header .ui-state-default li {
  color: #373737;
  font-family: Philosopher;
  font-weight: 700;
  opacity: 0.7;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  word-break: initial;
  white-space: normal;
}
.home-tabs .ui-widget-header li.ui-state-active {
  background: #fff;
  border-right: none;
  border-left: none;
  color: #373737;
  border-top-right-radius: 1.25rem;
  border-top-left-radius: 1.25rem;
  border-top: 5px solid #76a82f;
  position: relative !important;
  /* -webkit-box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.18); */
  /* box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.18); */
  -webkit-transition: display 0.3s ease-in-out,
    -webkit-box-shadow 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out, display 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out, display 0.3s ease-in-out,
    -webkit-box-shadow 0.3s ease-in-out;
  margin-bottom: 0;
  padding-bottom: 0;
}
.home-tabs .ui-widget-header li.ui-state-active:before {
  content: "";
  position: absolute;
  height: 23px;
  width: 41px;
  bottom: 0;
  left: -41px;
  z-index: 9;
  border-radius: 0 0 40px 0;
  -webkit-box-shadow: 20px 0 0 0 #fff;
  box-shadow: 20px 0 0 0 #fff;
}
.home-tabs .ui-widget-header li.ui-state-active:after {
  content: "";
  position: absolute;
  height: 23px;
  width: 41px;
  bottom: 0;
  right: -41px;
  z-index: 9;
  border-radius: 0 0 0 40px;
  -webkit-box-shadow: -20px 0 0 0 #fff;
  box-shadow: -20px 0 0 0 #fff;
}
.home-tabs .ui-widget-header li.ui-state-active li {
  opacity: 1;
}
.home-tabs .tabs-content {
  text-align: center;
  background: #fff;
  z-index: 1;
  position: relative;
}
@media (max-width: 991.98px) {
  .home-tabs .tabs-content {
    padding: 1em 0;
  }
  .home-tabs .tabs-content .ui-tabs-panel {
    padding-left: 0;
    padding-right: 0;
  }
}
.home-tabs .tabs-content .field_tabs_title {
  font-family: Philosopher;
  font-size: 1.5625rem;
  font-weight: 700;
  color: #373737;
  margin-bottom: 0.75rem;
}
.home-tabs .tabs-content .field_tabs_description {
  font-family: Montserrat;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: normal;
  color: #606060;
  margin-bottom: 1.75rem;
  max-width: 546px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.home-tabs .tabs-content .field_link {
  font-family: Montserrat;
  font-size: 0.875rem;
  opacity: 0.8;
  line-height: 1.43;
  letter-spacing: normal;
  margin-top: 1.375rem;
}
/* .home-tabs .tabs-content .field_link a {
    text-decoration: underline;
} */
.home-tabs .p-links,
.home-tabs .tabs-content .multiple-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.home-tabs .tabs-content .multiple-content {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .home-tabs #block-blocktabscorporate .tabs-content .multiple-content {
    margin-left: 16.66667%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
}
.home-tabs .p-links {
  width: 184px;
  height: 227px;
  margin: 1rem;
  padding: 1.5rem 1rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fff;
  border-radius: 0.625rem;
  -webkit-transition: all 0.8s ease;
  transition: all 0.8s ease;
  -webkit-box-shadow: 0 8px 12px rgba(0, 0, 0, 0.17641);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.17641);
  border: 2px solid transparent;
}
.home-tabs .p-links:hover {
  text-decoration: none;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
}
.home-tabs .p-links .icon {
  width: 84.8px;
  height: 85.6px;
  -o-object-fit: contain;
  object-fit: contain;
}
.home-tabs .p-links .name-icon {
  font-family: Philosopher;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 700;
  text-align: center;
  color: #373737;
  margin-top: 1.375rem;
}
@media (max-width: 575.98px) {
  .home-tabs .p-links {
    width: 164px;
    height: 200px;
  }
}
@media (max-width: 991.98px) {
  .home-tabs .ui-tabs .ui-tabs-nav li {
    width: 50% !important;
  }
  .home-tabs {
    margin-bottom: 80px;
    padding-left: 0;
    padding-right: 0;
  }
  .home-tabs .ui-tabs .ui-tabs-nav {
    padding: 0;
    width: 100%;
    display: block;
  }
  .home-tabs .ui-tabs .ui-tabs-nav li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.18);
    border-right: 1px solid rgba(0, 0, 0, 0.18);
  }
  .home-tabs .ui-tabs .ui-tabs-nav li:nth-child(n + 4) {
    border-bottom: none;
  }
  .home-tabs .ui-tabs .ui-tabs-nav li:nth-child(3n) {
    border-right: none;
    border-bottom: none;
  }
  .home-tabs .ui-tabs .ui-tabs-nav li {
    margin: 0;
  }
  .home-tabs .ui-tabs .ui-tabs-nav li:after,
  .home-tabs .ui-tabs .ui-tabs-nav li:before,
  .home-tabs .ui-widget-header li.ui-state-active:after,
  .home-tabs .ui-widget-header li.ui-state-active:before {
    display: none;
  }
  .home-tabs .ui-widget-header li {
    width: 33% !important;
    float: left !important;
    margin: 0;
  }
  .home-tabs .ui-widget-header li.ui-state-active {
    height: 105px;
    margin-top: -13px;
    border-bottom: none;
    border-right: none;
  }
}
@media screen and (max-width: 375px) {
  .home-tabs .ui-widget-header .ui-state-default li {
    font-size: 0.9rem;
  }
}

.ui-tabs {
  position: relative;
  padding: 0.2em;
}
.ui-tabs .ui-tabs-nav {
  margin: 0;
  padding: 0.2em 0.2em 0;
}
.ui-tabs .ui-tabs-nav li {
  list-style: none;
  float: left;
  position: relative;
  top: 0;
  margin: 1px 0.2em 0 0;
  border-bottom-width: 0;
  padding: 0;
  white-space: nowrap;
}
.ui-tabs .ui-tabs-nav .ui-tabs-anchor {
  float: left;
  padding: 0.5em 1em;
  text-decoration: none;
}
.ui-tabs .ui-tabs-nav li.ui-tabs-active {
  margin-bottom: -1px;
  padding-bottom: 1px;
}
.ui-tabs .ui-tabs-nav li.ui-tabs-active .ui-tabs-anchor,
.ui-tabs .ui-tabs-nav li.ui-state-disabled .ui-tabs-anchor,
.ui-tabs .ui-tabs-nav li.ui-tabs-loading .ui-tabs-anchor {
  cursor: text;
}
.ui-tabs-collapsible .ui-tabs-nav li.ui-tabs-active .ui-tabs-anchor {
  cursor: pointer;
}
.ui-tabs .ui-tabs-panel {
  display: block;
  border-width: 0;
  padding: 1em 1.4em;
  background: none;
}
.ui-widget {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
}
.ui-widget .ui-widget {
  font-size: 1em;
}

.ui-widget.ui-widget-content {
  border: 1px solid #c5c5c5 !important;
}
.ui-widget-content {
  border: 1px solid #dddddd;
  background: #ffffff;
  color: #333333;
}
.ui-widget-content {
  color: #333333;
}
.ui-widget-header {
  border: 1px solid #dddddd;
  background: #e9e9e9;
  color: #333333;
  font-weight: bold;
}
