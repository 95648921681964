.card {
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 21%;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.card:hover .text {
  background-color: #76a82f;
  color: white;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  transition-duration: 300ms;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.card:hover .text > h4 {
  color: white;
}

.text {
  padding: 2px 16px;
  text-align: center;
}

.text > h4 {
  margin-top: 13px;
  font-size: 15px;
}

.cover {
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

#container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border: 5px solid white;
  flex-wrap: wrap;
}

#cards {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

#title {
  margin-bottom: 55px;
}

@media only screen and (max-width: 768px) {
  #cards {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 1400px;
  }
  .card {
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 300px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
  }
}
