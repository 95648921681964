.social-media-links--platforms.horizontal li {
  display: inline-block;
  text-align: center;
  padding: 0.2em 0.35em;
}
.sub-footer a {
  color: #45723d;
}

footer .footer {
  color: #fff;
  background-color: #0d375d;
  padding: 30px 0;
}
footer .footer ul {
  padding-left: 0;
}
footer .footer ul li a {
  font-family: Montserrat;
  font-size: 1.2rem;
  line-height: 2.21;
  color: rgba(255, 255, 255, 0.6);
}
footer .footer ul li a.no-link {
  font-family: Philosopher;
  font-size: 20px;
  font-weight: 700;
  color: #45723d;
}

.fa {
  color: #373737;
}
.sub-footer {
  background: #0d375d;
}
.sub-footer .block-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-family: Montserrat;
  font-size: 10px;
  line-height: 1.5;
  color: #fff;
}
.sub-footer .footer-description {
  border-top: 1px solid;
  padding-bottom: 5px;
  padding-top: 12px;
}
.sub-footer p {
  font-size: 0.625rem;
  line-height: 1;
  color: rgba(255, 255, 255, 0.6);
}
@media (max-width: 767.98px) {
  .sub-footer .block-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .sub-footer .block-content > div {
    margin-bottom: 10px;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .sub-footer .block-content .field_logo_left {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .sub-footer .block-content .field_logo_right {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
}
.block-social-media-links ul {
  margin: 0;
}
.block-social-media-links .social-media-links--platforms li a {
  background-image: url(./icons/path-p.svg);
}
.block-social-media-links .social-media-links--platforms li a:hover,
.card-link:hover {
  text-decoration: none;
}
.block-social-media-links ul,
.simplenews-subscriber-form ul {
  margin: 0;
}

.block-social-media-links .social-media-links--platforms li {
  padding: 0;
  margin-top: 0.625rem;
}
.block-social-media-links .social-media-links--platforms li a {
  position: relative;
  display: block;
  text-align: center;
  margin-right: 9px;
  width: 41px;
  height: 41px;
  padding-top: 11px;
  font-size: 0.9rem;
}
.block-social-media-links .social-media-links--platforms li span:not(.fa) a {
  margin-top: -5px;
}
footer .footer ul li a.no-link {
  color: #76a82f;
}

.no-link {
  pointer-events: none;
  cursor: default;
}
footer .footer ul li a.no-link {
  font-family: Philosopher;
  font-size: 25px;
  font-weight: 700;
  /* color: #45723d; */
}
