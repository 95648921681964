.swiper-pointer-events {
  border-top-right-radius: 48px;
}
.slide {
  position: relative;
}
.slide::before {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: #00000048;
  z-index: 1;
}
.slide h3 {
  z-index: 2;
}
.main-menu ul.sub-menu a.is-active,
.main-menu ul.sub-menu li.more a.is-active,
.sub-footer a {
  color: #45723d;
}
@media (min-width: 992px) {
  .main-menu {
    height: 60px;
  }
  .light-menu,
  .main-menu,
  .main-menu ul.sub-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .main-menu ul.sub-menu {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0;
    padding: 0 20px 0 60px;
    height: 50px;
    background-color: #fff;
    border-top: solid 1px #41633b;
    border-bottom-right-radius: 20px;
    -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.17);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.17);
    -webkit-transform: skew(-15deg);
    transform: skew(-15deg);
    position: absolute;
    top: 0;
    left: 100%;
    z-index: -1;
  }
  .light-menu ul.sub-menu > li,
  .main-menu ul.sub-menu > li {
    -webkit-transform: skew(15deg);
    transform: skew(15deg);
  }
  .light-menu > div:before,
  .main-menu ul.sub-menu > li > a:after {
    content: "";
    position: absolute;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
  .main-menu ul.sub-menu > li > a:after {
    -webkit-mask-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPâ€¦AwMDAsIC0zMC4wMDAwMDApICI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
    mask-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPâ€¦AwMDAsIC0zMC4wMDAwMDApICI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    right: 0;
    top: 0;
    width: 60px;
    height: 60px;
  }
  .main-menu ul.sub-menu a,
  .main-menu ul.sub-menu li.more span {
    font-family: Philosopher;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    color: #373737;
    padding: 5px 10px;
    display: block;
    white-space: nowrap;
    cursor: pointer;
  }
  .light-menu ul.sub-menu li.more,
  .main-menu ul.sub-menu li.more {
    text-align: center;
    display: none;
  }
  /* .light-menu ul.sub-menu li.more:hover > ul.overflow,
    .main-menu ul.sub-menu li.more:hover > ul.overflow {
        opacity: 1;
        visibility: visible;
    } */
  .light-menu ul.sub-menu li .overflow,
  .main-menu ul.sub-menu li .overflow {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    right: -50%;
    z-index: 10;
    margin: 0;
    padding: 0;
    text-align: left;
    background-color: #fff;
    border-radius: 5px;
    -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.57);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.57);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
  .light-menu {
    height: 60px;
    position: fixed;
    width: 100%;
    z-index: 100;
    top: -70px;
    left: 0;
    background: #fff;
    -webkit-box-shadow: 0 0.5rem 1.0625rem rgba(55, 55, 55, 0.15);
    box-shadow: 0 0.5rem 1.0625rem rgba(55, 55, 55, 0.15);
    -webkit-transition: top 0.3s;
    transition: top 0.3s;
  }
  .light-menu > div {
    /* background: #fff; */
    height: 68px;
    width: 150px;
  }
  /* .light-menu > div:before {
        left: 70px;
        top: 34px;
        width: 40px;
        height: 34px;
        background: #fff;
        mask-mode: alpha;
        -webkit-mask-size: cover;
        mask-size: cover;
        -webkit-mask-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNzdweCIgaGVpZ2h0PSI3NnB4IiB2aWV3Qm94PSIwIDAgNzcgNzYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+R3JvdXAgMiBDb3B5IDI3PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMS1taXNlLWFqb3VyLS0iIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJBcnRib2FyZCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIzNS4wMDAwMDAsIC0yOC4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTU0LDI4IEwzMzUuNjM2NzE5LDI4IEwzMTAuMDk4ODYsMjguMDM4Mzk1OSBDMzAwLjAwOTk1NywyOC4wNTM1NjQ1IDI5MC45MTg5MzQsMzQuMTMxNDAwOCAyODcuMDQ4ODk0LDQzLjQ0ODUzNDMgTDI3NS4wMTI2NzUsNzIuNDI1NzY2OCBDMjY5LjU4NzI3Myw4NS40ODc0Mzg0IDI1Ni44MzM3MzEsOTQgMjQyLjY5MDEwMSw5NCBMNTQsOTQgTDU0LDk0IEw1NCwyOCBaIiBpZD0iUmVjdGFuZ2xlLUNvcHktNSI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
        mask-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNzdweCIgaGVpZ2h0PSI3NnB4IiB2aWV3Qm94PSIwIDAgNzcgNzYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+R3JvdXAgMiBDb3B5IDI3PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMS1taXNlLWFqb3VyLS0iIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJBcnRib2FyZCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIzNS4wMDAwMDAsIC0yOC4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTU0LDI4IEwzMzUuNjM2NzE5LDI4IEwzMTAuMDk4ODYsMjguMDM4Mzk1OSBDMzAwLjAwOTk1NywyOC4wNTM1NjQ1IDI5MC45MTg5MzQsMzQuMTMxNDAwOCAyODcuMDQ4ODk0LDQzLjQ0ODUzNDMgTDI3NS4wMTI2NzUsNzIuNDI1NzY2OCBDMjY5LjU4NzI3Myw4NS40ODc0Mzg0IDI1Ni44MzM3MzEsOTQgMjQyLjY5MDEwMSw5NCBMNTQsOTQgTDU0LDk0IEw1NCwyOCBaIiBpZD0iUmVjdGFuZ2xlLUNvcHktNSI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
    } */
  .light-menu img.brand-logo {
    display: none;
    margin: 12px 25px;
  }
  .light-menu .c-menu-main-menu {
    margin-left: 44px;
  }
  .light-menu .c-menu-main-menu > li {
    margin-left: 49px;
  }
  .light-menu .c-menu-main-menu > li > a:before {
    height: 60px;
    width: 60px !important;
    left: -60px !important;
  }
  .light-menu .c-menu-main-menu > li > a:after {
    height: 60px;
    width: 60px;
    right: -60px;
  }
  .light-menu .c-menu-main-menu > li > a span {
    font-size: 1.8rem;
    line-height: 60px;
  }
  /* .light-menu .c-menu-main-menu > li > a:hover span {
        width: 130px;
    } */
  .light-menu .c-menu-main-menu > li:nth-child(3) > a span,
  .particuliers-theme .light-menu .c-menu-main-menu > li:nth-child(1) > a span {
    width: 130px;
  }
  .light-menu ul.sub-menu,
  .light-menu ul.t-menu-menu-top-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
  }
  .light-menu ul.sub-menu {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0;
    padding: 0 0 0 40px;
    height: 60px;
    -webkit-transform: skew(-15deg);
    transform: skew(-15deg);
    top: 0;
    left: 100%;
    z-index: -1;
  }
  .light-menu ul.sub-menu a,
  .light-menu ul.sub-menu li.more span {
    font-family: Philosopher;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    color: #373737;
    padding: 5px 6px;
    display: block;
    white-space: nowrap;
    cursor: pointer;
  }
  .light-menu ul.t-menu-menu-top-header {
    top: 5px;
    right: 10px;
  }
  .light-menu ul.t-menu-menu-top-header a {
    padding: 5px 0;
  }
  .light-menu ul.t-menu-menu-top-header a.particulier {
    margin: 0 10px 0 15px;
  }
  .light-menu ul.t-menu-menu-top-header a.professionnel {
    margin-right: 15px;
  }
  .light-menu ul.t-menu-menu-top-header a.corporate {
    font-size: 0.875rem;
    position: relative;
    color: #727272;
    margin: 0 15px;
    border-radius: 0;
    border: 0;
  }
  .light-menu ul.t-menu-menu-top-header a.tawassol {
    padding: 5px 10px;
  }
  .block-language .title,
  .c-menu-main-menu > li > a .link-before,
  .sub-menu .more > span {
    display: none;
  }
  .sub-menu ul.overflow {
    padding: 0;
  }
  .c-menu-main-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    font-size: 0;
    margin: 0 0 0 -59px;
    padding: 0;
    list-style: none;
    position: relative;
  }
  .c-menu-main-menu > li {
    margin: 0 0 0 59px;
    z-index: 3;
    -webkit-transition: all 0.6s;
    transition: all 0.6s;
  }
  .c-menu-main-menu > li > a,
  .c-menu-main-menu > li > a span {
    display: block;
    text-align: center;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }
  .c-menu-main-menu > li > a {
    margin: 0;
    padding: 0;
    text-decoration: none;
    background-color: #41633b;
    position: relative;
  }
  .c-menu-main-menu > li > a span {
    overflow: hidden;
    width: 0;
    font-family: Philosopher;
    font-size: 18px;
    font-weight: 700;
    line-height: 60px;
    color: #fff;
    white-space: nowrap;
    opacity: 0;
  }
  .c-menu-main-menu > li > a:after,
  .c-menu-main-menu > li > a:before {
    content: "";
    background-color: #41633b;
    position: absolute;
    top: 0;
    width: 60px;
    height: 60px;
    mask-mode: alpha;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNjBweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNjAgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UGF0aCAxNUAzeDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJBcnRib2FyZC1Db3B5LTIiIGZpbGw9IiMyOTY4QjIiPgogICAgICAgICAgICA8cGF0aCBkPSJNNjAsMCBDNDQuNTQ5NDQxNywxLjk3NDYwOTM4IDM0LjU0OTQ0MTcsMTEuOTc0NjA5NCAzMCwzMCBDMjUuNDUwNTU4Myw0OC4wMjUzOTA2IDE1LjQ1MDU1ODMsNTguMDI1MzkwNiAwLDYwIEwwLDAgTDI5Ljg5MDEzNjcsMCBMNjAsMCBaIiBpZD0iUGF0aC0xNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzAuMDAwMDAwLCAzMC4wMDAwMDApIHNjYWxlKC0xLCAtMSkgdHJhbnNsYXRlKC0zMC4wMDAwMDAsIC0zMC4wMDAwMDApICI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
    mask-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNjBweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNjAgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UGF0aCAxNUAzeDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJBcnRib2FyZC1Db3B5LTIiIGZpbGw9IiMyOTY4QjIiPgogICAgICAgICAgICA8cGF0aCBkPSJNNjAsMCBDNDQuNTQ5NDQxNywxLjk3NDYwOTM4IDM0LjU0OTQ0MTcsMTEuOTc0NjA5NCAzMCwzMCBDMjUuNDUwNTU4Myw0OC4wMjUzOTA2IDE1LjQ1MDU1ODMsNTguMDI1MzkwNiAwLDYwIEwwLDAgTDI5Ljg5MDEzNjcsMCBMNjAsMCBaIiBpZD0iUGF0aC0xNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzAuMDAwMDAwLCAzMC4wMDAwMDApIHNjYWxlKC0xLCAtMSkgdHJhbnNsYXRlKC0zMC4wMDAwMDAsIC0zMC4wMDAwMDApICI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
  }
  .c-menu-main-menu > li > a:before {
    left: -60px;
  }
  .c-menu-main-menu > li > a:after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    right: -60px;
  }
  .c-menu-main-menu > li > a span {
    opacity: 1;
    width: 130px;
  }
  .c-menu-main-menu > li:nth-child(1) > a {
    background-color: #76a82f;
    z-index: 2;
  }
  .c-menu-main-menu > li:nth-child(1) > a:after,
  .c-menu-main-menu > li:nth-child(1) > a:before {
    background-color: #76a82f;
  }
  .c-menu-main-menu > li:nth-child(2) > a {
    background-color: #2968b2;
    z-index: 1;
  }
  /* .light-menu .c-menu-main-menu > li:nth-child(2) > a span,
    .c-menu-main-menu > li:nth-child(2) > a:hover span {
        width: 240px;
    } */
  .c-menu-main-menu > li:nth-child(2) > a:after,
  .c-menu-main-menu > li:nth-child(2) > a:before {
    background-color: #2968b2;
  }
  .c-menu-main-menu > li:nth-child(3) {
    display: inline-block;
  }
  .c-menu-main-menu > li:nth-child(3) > a span,
  .particuliers-theme .c-menu-main-menu > li:nth-child(1) > a span,
  .path-business .c-menu-main-menu > li:nth-child(1) > a span {
    opacity: 1;
    width: 130px;
  }
  .c-menu-main-menu > li:nth-child(1) > a:before,
  .c-menu-main-menu > li:last-child > a:before,
  .particuliers-theme .c-menu-main-menu > li:nth-child(1) > a:before {
    left: -52px;
    width: 54px;
    -webkit-mask-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNTFweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNTEgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UmVjdGFuZ2xlIENvcHkgNDI8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xLW1pc2UtYWpvdXItLSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwLTQtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM1MC4wMDAwMDAsIC01MS4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTQwMC45NjE4NzcsNTEuMSBMNDAxLDUxLjEgTDQwMSw1MS4xIEw0MDEsMTEwLjEgTDM1MC4wMTQ0NDUsMTEwLjEgTDM2OS41OTg5MTksNzAuNTY0MDc3MyBDMzc1LjUwNDMxNiw1OC42NDI2MjkgMzg3LjY1Nzk0NCw1MS4xIDQwMC45NjE4NzcsNTEuMSBaIiBpZD0iUmVjdGFuZ2xlLUNvcHktNDIiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
    mask-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNTFweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNTEgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UmVjdGFuZ2xlIENvcHkgNDI8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xLW1pc2UtYWpvdXItLSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwLTQtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM1MC4wMDAwMDAsIC01MS4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTQwMC45NjE4NzcsNTEuMSBMNDAxLDUxLjEgTDQwMSw1MS4xIEw0MDEsMTEwLjEgTDM1MC4wMTQ0NDUsMTEwLjEgTDM2OS41OTg5MTksNzAuNTY0MDc3MyBDMzc1LjUwNDMxNiw1OC42NDI2MjkgMzg3LjY1Nzk0NCw1MS4xIDQwMC45NjE4NzcsNTEuMSBaIiBpZD0iUmVjdGFuZ2xlLUNvcHktNDIiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 70px;
  padding: 0px 6px;
  right: -26px;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

@media (max-width: 992px) {
  .swiper {
    height: 340px;
  }

  .sliderImg {
    height: 340px;
    width: 100%;
  }

  .slogan {
    top: 228px !important;
    transform: scale(0.7);
    right: -51px !important;
  }

  #welcoming {
    width: 100%;
    left: 0px !important;
    top: 120px !important;
    font-size: 30px !important;
  }

  #egltd {
    width: 100%;
    left: 4px !important;
    top: 190px !important;
    width: 100%;
    font-size: 25px !important;
  }

  #cpm {
    width: 100%;
    left: 0px !important;
    top: 120px !important;
    font-size: 21px !important;
  }

  #dtpi {
    width: 100%;
    left: 0px !important;
    top: 174px !important;
    font-size: 21px !important;
  }

  #ogard {
    width: 100%;
    left: 0px !important;
    top: 225px !important;
    font-size: 15px !important;
  }

  #usgrbue {
    width: 100%;
    left: 0px !important;
    top: 132px !important;
    font-size: 23px !important;
  }
}
