.about-bg {
  background-image: url("../../images/usa-uk.png");
  background-repeat: no-repeat;
  height: 50rem;
  background-size: cover;
  background-position: center;
}
.ceo-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: linear-gradient(45deg, #6a0809, #363c80);
}
.about-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 135px;
  position: relative;
}
.country-flag {
  width: 215px;
}
.about-bottom-card {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 50px;
}
.about-bottom-content {
  width: 60%;
}
.ceo-image,
.about-title {
  position: absolute;
}
.ceo-image {
  bottom: -60px;
}
.about-title {
  bottom: -130px;
  font-weight: 400;
}
.about-bottom-content p {
  margin-bottom: 20px;
}
.about-bottom-content a {
  color: #2968b2;
  font-weight: 500;
  background: -webkit-linear-gradient(135deg, #76a82f, #2968b2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 15px;
  border: 2px solid #eee;
  padding: 7px 20px;
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
  margin-top: 20px;
}
.about-bottom-content a:hover {
  -webkit-background-clip: border-box;
  -webkit-text-fill-color: #fff;
  background: -webkit-linear-gradient(45deg, #76a82f, #2968b2);
}
