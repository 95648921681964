.our-team {
  padding: 30px 0 40px;
  margin-bottom: 30px;
  margin-top: 40px;
  background-color: #f7f5ec;
  text-align: center;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.our-team .picture {
  display: inline-block;
  height: 150px;
  width: 150px;
  margin-bottom: 50px;
  z-index: 1;
  position: relative;
}

.our-team .picture::before {
  content: "";
  width: 100%;
  height: 0;
  border-radius: 50%;
  background-color: #1369ce;
  position: absolute;
  bottom: 135%;
  right: 0;
  left: 0;
  opacity: 0.9;
  transform: scale(3);
  transition: all 0.3s linear 0s;
}

.our-team:hover .picture::before {
  height: 100%;
}

.our-team .picture::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #1369ce;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.our-team .picture img {
  /* width: 100%; */
  height: 150px;
  width: 150px;
  /* height: auto; */
  border-radius: 50%;
  transform: scale(1);
  transition: all 0.9s ease 0s;
}

.our-team:hover .picture img {
  box-shadow: 0 0 0 14px #f7f5ec;
  transform: scale(0.7);
}

.our-team .title {
  display: block;
  font-size: 15px;
  color: #4e5052;
  text-transform: capitalize;
}

.our-team .social-media {
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: #1369ce;
  position: absolute;
  bottom: -100px;
  left: 0;
  transition: all 0.5s ease 0s;
}

.our-team:hover .social-media {
  bottom: 0;
}

.our-team .social-media li {
  display: inline-block;
}

.our-team .social-media li a {
  display: block;
  padding: 10px;
  font-size: 17px;
  color: white;
  transition: all 0.3s ease 0s;
  text-decoration: none;
}

.our-team .social-media li a:hover {
  color: #1369ce;
  background-color: #f7f5ec;
}

/* @media (min-width: 768px) and (max-width: 991px) {
    .u-sheet {
        width: var(--theme-sheet-width-md) !important;
    }
}
@media (min-width: 576px) and (max-width: 767px) {
    .u-sheet {
        width: var(--theme-sheet-width-sm) !important;
    }
}
@media (max-width: 575px) {
    .u-sheet {
        width: var(--theme-sheet-width-xs) !important;
    }
} */
@media (max-width: 1193px) {
  .our-team {
    width: 255px;
    /* height: 180px; */
  }
}
@media (max-width: 991px) {
  /* .our-team {
        width: 210px;
         height: 180px; 
    } 
    */

  .our-team {
    width: 210px;
    /* height: 180px; */
  }
  .Block-cards {
    flex-direction: column;
  }
  /* .bloc {
        margin-bottom: 20px;
        width: 100%;
    } */

  .team-responsive {
    flex-direction: column;
  }
  .Block-cards {
    flex-direction: column;
  }
  .our-team {
    width: 280px;
  }
  /* .bloc {
        margin-bottom: 20px;
        width: 100%;
    } */

  .bloc {
    margin-bottom: 20px;
    height: 100%;
    width: 100%;
  }
  .bloc p {
    width: 200px;
  }
  .bloc h4 {
    width: 214px;
  }
  #first-bloc {
    height: 360px;
  }
}
