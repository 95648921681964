@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900|Philosopher:400,400i,700,700i&display=swap);

.SampleProject {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin-bottom: 3rem;
    margin-top: 2rem;
}
.SampleProject .date {
    margin: 0.8rem 0 0;
}
.SampleProject .img {
    height: 346px;
    width: 470px;
    border-radius: 20px;
    background-color: #d8d8d8;
    -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
}
.SampleProject .img div {
    height: 100%;
}
.SampleProject .img img {
    height: 100%;
    width: 100%;
    border-radius: 20px;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
}
.SampleProject .img .img-sub-header img {
    -o-object-position: 91% center;
    object-position: 91% center;
    font-family: "object-fit: cover; object-position: 91% center;";
}
.SampleProject .content {
    width: 100%;
}
.SampleProject .title {
    font-family: Philosopher;
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    color: #41633b;
    margin-top: 80px;
}
.SampleProject .description {
    color: #606060;
    font-family: Montserrat;
    font-size: 18px;
    line-height: 52px;
    width: 90%;
    margin-top: 40px !important;
}
.SampleProject .description p {
    font-size: 18px;
    text-align: justify;
}
.SampleProject .read-more {
    font-family: Philosopher;
    font-size: 1.0625rem;
    font-weight: 700;
    line-height: 2.1875rem;
}
.SampleProject .read-more ul {
    margin-top: 1rem;
    padding-left: 0;
}
.SampleProject:nth-child(odd) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}
.SampleProject:nth-child(odd) .img {
    margin-right: 1.875rem;
}
.SampleProject:nth-child(even) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}
.SampleProject:nth-child(even) .img {
    margin-left: 1.875rem;
}

@media (max-width: 991.98px) {
    .SampleProject .description p {
        font-size: 1.65rem !important;
        text-align: justify;
        line-height: normal;
        font-size: x-large;
    }
    .SampleProject {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-bottom: 1.875rem;
    }
    .SampleProject .img-container {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    }
    .SampleProject .title {
        font-size: 1.8625rem;
    }
    .SampleProject .description,
    .SampleProject .description p {
        font-size: 0.75rem;
    }
    .SampleProject .img {
        width: 100%;
        margin-bottom: 1.25rem;
        margin-right: 0;
    }
    .SampleProject:nth-child(odd) .img {
        margin-right: 0;
    }
    .SampleProject:nth-child(even) .img {
        margin-left: 0;
    }
}

@media (max-width: 992px) {
    #imgTitle {
        top: 120px !important;
        font-size: 35px !important;
    }

    #coag {
        /* margin-top: -68px !important; */
        text-align: center !important;
    }

    #hod {
        margin-top: -68px !important;
    }

    #satop {
        margin-top: -88px !important;
    }

    #osd {
        margin-top: -70px !important;
    }

    #pipelines {
        margin-top: -15px !important;
    }

    #tat {
        margin-top: -15px !important;
    }

    #swgabs {
        margin-top: -15px !important;
    }

    #fsal {
        margin-top: -20px !important;
    }

    .description {
        margin-top: -10px !important;
        margin-left: 15px !important;
    }
}
