.D-title {
    font-family: Philosopher;
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    color: #41633b;
}
.D-description {
    color: #606060;
    font-family: Montserrat;
    font-size: 18px;
    line-height: 32px;
}
.D-description p {
    font-size: 18px;
    text-align: justify;
}

.Digital {
    width: 100%;
    height: auto;
    min-height: 100vh;
    padding: 50px 80px;
    padding-top: 60px;
    display: flex;
}

.video-react .video-react-big-play-button {
    top: 142px !important;
    left: 361px !important;
}

@media (min-width: 1380px) {
    .video-react .video-react-big-play-button {
        top: 200px !important;
        left: 420px !important;
    }
}
@media (min-width: 1580px) {
    .video-react .video-react-big-play-button {
        top: 200px !important;
        left: 490px !important;
    }
}
