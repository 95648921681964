@import url("https://fonts.googleapis.com/css?family=Quicksand:400,500,700&subset=latin-ext");
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900|Philosopher:400,400i,700,700i&display=swap");
/* html {
  position: relative;
  overflow-x: hidden !important;
}

* {
  box-sizing: border-box;
}

body {
  font-family: "Quicksand", sans-serif;
  color: #324e63;
} */
a,
a:hover {
    text-decoration: none;
}

.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
}

.wrapper {
    width: 100%;
    width: 100%;
    height: auto;
    min-height: 100vh;
    padding: 50px 20px;
    padding-top: 100px;
    display: flex;
}
@media screen and (max-width: 768px) {
    .wrapper {
        height: auto;
        min-height: 100vh;
        padding-top: 100px;
    }
}

.profile-card {
    width: 100%;
    min-height: 460px;
    margin: auto;
    box-shadow: 0px 8px 60px -10px rgba(13, 28, 39, 0.6);
    background: #fff;
    /* border-radius: 12px; */
    border-top-left-radius: 120px;
    max-width: 700px;
    position: relative;
}
.profile-card.active .profile-card__cnt {
    filter: blur(6px);
}
.profile-card.active .profile-card-message,
.profile-card.active .profile-card__overlay {
    opacity: 1;
    pointer-events: auto;
    transition-delay: 0.1s;
}
.profile-card.active .profile-card-form {
    transform: none;
    transition-delay: 0.1s;
}
.profile-card__img {
    width: 150px;
    height: 150px;
    /* margin-left: auto; */
    /* margin-right: auto; */
    transform: translateY(-50%);
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    top: 105px;
    left: 70px;
    position: absolute;
    z-index: 4;
    box-shadow: 0px 5px 50px 0px #85fc44, 0px 0px 0px 7px rgb(1 112 8 / 51%);
}
.swiper-button-next,
.swiper-button-prev {
    top: 154px;
}

.social {
    transform: translateY(50px);
    left: 0;
    padding: 15px 0px;
    position: absolute;
    text-align: center;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    z-index: 1;
    width: 100%;
    text-align: center;
}
.social ul {
    background: #274e8dd6 none repeat scroll 0 0;
    display: flex;
    justify-content: center;
    padding: 10px 30px;
}
.social li {
    display: flex;
}
.social li a {
    display: flex;
    font-size: 20px;
    margin: 0px 5px 0;
    background-color: rgba(255, 255, 255, 0.568);
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.profile-card__img:hover .social {
    transform: translateY(-60px);
    transition: all 0.7s ease-in-out;
}
@media screen and (max-width: 576px) {
    .profile-card__img {
        width: 120px;
        height: 120px;
    }
}
.profile-card__img .profile_card_img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}
.profile-card__cnt {
    margin-top: -35px;
    text-align: center;
    padding: 0 20px;
    padding-bottom: 40px;
    transition: all 0.3s;
}
.profile-card__name {
    font-weight: 700;
    font-size: 32px;
    color: #fff;
    position: absolute;
    top: 54px;
    right: 133px;
}
.profile-card__txt {
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    top: 99px;
    right: 151px;
    position: absolute;
}
.profile-card__txt strong {
    font-weight: 700;
}
.profile-card-loc {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
}
.profile-card-loc__icon {
    display: inline-flex;
    font-size: 27px;
    margin-right: 10px;
}
.profile-card-inf {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: 35px;
}
.profile-card-inf__item {
    padding: 10px 35px;
    min-width: 150px;
}
@media screen and (max-width: 768px) {
    .profile-card-inf__item {
        padding: 10px 20px;
        min-width: 120px;
    }
}
.profile-card-inf__title {
    font-weight: 700;
    font-size: 27px;
    color: #324e63;
}
.profile-card-inf__txt {
    font-weight: 500;
    margin-top: 7px;
}
.profile-card-social {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.profile-card-social__item {
    display: inline-flex;
    width: 55px;
    height: 55px;
    margin: 15px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #405de6;
    box-shadow: 0px 7px 30px rgba(43, 98, 169, 0.5);
    position: relative;
    font-size: 21px;
    flex-shrink: 0;
    transition: all 0.3s;
}
@media screen and (max-width: 768px) {
    .profile-card-social__item {
        width: 50px;
        height: 50px;
        margin: 10px;
    }
}
@media screen and (min-width: 768px) {
    .profile-card-social__item:hover {
        transform: scale(1.2);
    }
}
.profile-card-social__item.facebook {
    background: linear-gradient(45deg, #3b5998, #0078d7);
    box-shadow: 0px 4px 30px rgba(43, 98, 169, 0.5);
}
.profile-card-social__item.twitter {
    background: linear-gradient(45deg, #1da1f2, #0e71c8);
    box-shadow: 0px 4px 30px rgba(19, 127, 212, 0.7);
}
.profile-card-social__item.instagram {
    background: linear-gradient(
        45deg,
        #405de6,
        #5851db,
        #833ab4,
        #c13584,
        #e1306c,
        #fd1d1d
    );
    box-shadow: 0px 4px 30px rgba(120, 64, 190, 0.6);
}
.profile-card-social__item.behance {
    background: linear-gradient(45deg, #1769ff, #213fca);
    box-shadow: 0px 4px 30px rgba(27, 86, 231, 0.7);
}
.profile-card-social__item.github {
    background: linear-gradient(45deg, #333333, #626b73);
    box-shadow: 0px 4px 30px rgba(63, 65, 67, 0.6);
}
.profile-card-social__item.codepen {
    background: linear-gradient(45deg, #324e63, #414447);
    box-shadow: 0px 4px 30px rgba(55, 75, 90, 0.6);
}
.profile-card-social__item.link {
    background: linear-gradient(45deg, #d5135a, #f05924);
    box-shadow: 0px 4px 30px rgba(223, 45, 70, 0.6);
}
.profile-card-social .icon-font {
    display: inline-flex;
}
.profile-card-ctr {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}
@media screen and (max-width: 576px) {
    .profile-card-ctr {
        flex-wrap: wrap;
    }
}
.profile-card__button {
    background: none;
    border: none;
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-size: 19px;
    margin: 15px 35px;
    padding: 15px 40px;
    min-width: 201px;
    border-radius: 50px;
    min-height: 55px;
    color: #fff;
    cursor: pointer;
    backface-visibility: hidden;
    transition: all 0.3s;
}
@media screen and (max-width: 768px) {
    .profile-card__button {
        min-width: 170px;
        margin: 15px 25px;
    }
}
@media screen and (max-width: 576px) {
    .profile-card__button {
        min-width: inherit;
        margin: 0;
        margin-bottom: 16px;
        width: 100%;
        max-width: 300px;
    }
    .profile-card__button:last-child {
        margin-bottom: 0;
    }
}
.profile-card__button:focus {
    outline: none !important;
}
@media screen and (min-width: 768px) {
    .profile-card__button:hover {
        transform: translateY(-5px);
    }
}
.profile-card__button:first-child {
    margin-left: 0;
}
.profile-card__button:last-child {
    margin-right: 0;
}
.profile-card__button.button--blue {
    background: linear-gradient(45deg, #1da1f2, #0e71c8);
    box-shadow: 0px 4px 30px rgba(19, 127, 212, 0.4);
}
.profile-card__button.button--blue:hover {
    box-shadow: 0px 7px 30px rgba(19, 127, 212, 0.75);
}
.profile-card__button.button--orange {
    background: linear-gradient(45deg, #d5135a, #f05924);
    box-shadow: 0px 4px 30px rgba(223, 45, 70, 0.35);
}
.profile-card__button.button--orange:hover {
    box-shadow: 0px 7px 30px rgba(223, 45, 70, 0.75);
}
.profile-card__button.button--gray {
    box-shadow: none;
    background: #dcdcdc;
    color: #142029;
}
.profile-card-message {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 130px;
    padding-bottom: 100px;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;
}
.profile-card-form {
    box-shadow: 0 4px 30px rgba(15, 22, 56, 0.35);
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    background: #fff;
    border-radius: 10px;
    padding: 35px;
    transform: scale(0.8);
    position: relative;
    z-index: 3;
    transition: all 0.3s;
}
@media screen and (max-width: 768px) {
    .profile-card-form {
        max-width: 90%;
        height: auto;
    }
}
@media screen and (max-width: 576px) {
    .profile-card-form {
        padding: 20px;
    }
}
.profile-card-form__bottom {
    justify-content: space-between;
    display: flex;
}
@media screen and (max-width: 576px) {
    .profile-card-form__bottom {
        flex-wrap: wrap;
    }
}
.profile-card textarea {
    width: 100%;
    resize: none;
    height: 210px;
    margin-bottom: 20px;
    border: 2px solid #dcdcdc;
    border-radius: 10px;
    padding: 15px 20px;
    color: #324e63;
    font-weight: 500;
    font-family: "Quicksand", sans-serif;
    outline: none;
    transition: all 0.3s;
}
.profile-card textarea:focus {
    outline: none;
    border-color: #8a979e;
}
.profile-card__overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    opacity: 0;
    background: rgba(22, 33, 72, 0.35);
    border-radius: 12px;
    transition: all 0.3s;
}

/* Header cover */
.main-head {
    position: relative;
    display: block;
    margin-top: 50px;
}
.main-head,
.main-head .rev_slider > ul {
    border-top-right-radius: 50px;
}
/* .main-head .tp-bgimg {
    -webkit-box-shadow: inset 0 60px 60px 20px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 60px 60px 20px rgba(0, 0, 0, 0.3);
} */
@media (max-width: 991.98px) {
    .main-head {
        margin-top: 0px;
        border-top-right-radius: 20px;
    }
    .main-head .rev_slider > ul {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
}
.hero-header {
    position: relative;
    display: block;
    width: 100%;
    height: 330px;
    color: #fff;
    overflow: hidden;
    /* background-color: #d8d8d8; */
    background-size: cover;
    background-position: top right;
}
.hero-header:before {
    content: "";
    background-repeat: no-repeat;
    background-position: left bottom;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
}
@media (max-width: 991.98px) {
    .hero-header:after {
        border-radius: 0 !important;
    }
    .hero-header:before {
        top: 129px;
    }
}
.hero-header:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 50px;
    z-index: 0;
}
.hero-header h1 {
    font-size: 1.875rem;
    /* text-shadow: 0 2px 4px rgba(0, 0, 0, 0.13); */
    font-weight: 700;
}
.hero-header .simple h1,
.tp-caption h3,
.tp-caption span {
    text-align: center;
}
@media (min-width: 992px) {
    .hero-header {
        padding-top: 4.375rem;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
        border-bottom-right-radius: 50px;

        height: 400px;
    }
    .hero-header h1 {
        font-size: 3.125rem;
    }
}

.filters {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.b-filter a,
.filters {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.b-filter a:hover .name,
.filters-footer .b-filter-icons .b-filter a .name {
    color: #373737;
}
.b-filter {
    width: 155px;
    height: 175px;
    margin: 1rem;
}
@media (max-width: 991.98px) {
    .b-filter {
        margin: 4px;
    }
}
.b-filter a {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0.3125rem;
    border: solid 0.8px #e0e0e0;
    border-radius: 1rem;
    -webkit-transition: all 0.8s ease;
    transition: all 0.8s ease;
}
.b-filter a .icon {
    width: 70px;
    height: 70px;

    margin-bottom: 1rem;
}
.b-filter a .name {
    font-family: Philosopher;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 700;
    text-align: center;
    color: rgba(55, 55, 55, 0.5);
}
.b-filter a:hover {
    text-decoration: none;
    -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
}

.team-background {
    position: relative;
    padding: 4.1875rem 7.25rem;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 50px;
    width: 70%;
    height: 100%;
    background: linear-gradient(
        304.2deg,
        rgba(3, 147, 192, 0.06) 0,
        rgba(41, 104, 178, 0.06) 100%
    );
    border-radius: 2.5rem;
}
.profile-card-r {
    height: 230px;
    background: linear-gradient(
            217deg,
            rgb(12 157 18 / 51%),
            rgba(255, 0, 0, 0) 70.71%
        ),
        linear-gradient(127deg, rgb(10 92 128), rgba(0, 255, 0, 0) 90.71%);
    border-top-left-radius: 120px;
    border-bottom-right-radius: 120px;
}
.bloc-logo {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjg1NDRBNjg4MjQyMDExRUFCMjlCQkQ4RDFGNUJGMjc0IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjg1NDRBNjg5MjQyMDExRUFCMjlCQkQ4RDFGNUJGMjc0Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6ODU0NEE2ODYyNDIwMTFFQUIyOUJCRDhEMUY1QkYyNzQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6ODU0NEE2ODcyNDIwMTFFQUIyOUJCRDhEMUY1QkYyNzQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5eVGSLAAAAD0lEQVR42mL4//8/QIABAAX+Av4tzonuAAAAAElFTkSuQmCC);
    background-size: calc(100% - 97px) 100%;
    background-repeat: no-repeat;
}
@media (max-width: 1920px) {
    .field_title_header {
        right: 0% !important;
        top: 196px !important;
        width: 100%;
        text-align: center;
    }
}
@media (max-width: 991px) {
}
@media (max-width: 768px) {
}

@media (max-width: 661px) {
}
@media (max-width: 991px) {
    #faq-hero-header,
    #partners-hero-header,
    #busgb {
        background-position: center !important ;
    }
    .field_title_header {
        right: 0% !important;
        top: 157px !important;
        width: 100%;
        text-align: center;
    }

    .team-background {
        background: transparent;
        padding: 0px;
        width: 100%;
    }
    .team-background .wrapper {
        padding: 0px;
    }
    .profile-card {
        width: 350px;
    }
    .profile-card-r {
        height: 350px;
    }
    .profile-card__txt {
        top: 221px;
        right: 86px;
    }
    .profile-card__name {
        top: 190px;
        right: 57px !important;
    }
    .profile-card__img {
        left: 115px;
    }
    .swiper-button-next,
    .swiper-button-prev {
        transform: scale(0.5);
        top: 250px;
    }
    .JfA {
        padding: 30px !important;
        font-size: 14px !important;
    }
    .CfA {
        font-size: 14px !important;
        padding: 30px !important;
    }
    #fC1 {
        transform: translateY(90px) !important;
    }
    .profile-card__txt {
        left: 40px !important;
    }
    #RfA4 {
        transform: translateY(100px) !important;
    }
    #RfA2,
    #RfA1 {
        transform: translateY(80px) !important;
    }
}

/****/
@media (max-width: 991.98px) {
    .main-menu .block-language ul.satoripop-language-item {
        display: block;
    }
    .main-menu .block-language .title {
        font-family: Philosopher;
        padding: 23px 15px;
        font-size: 18px;
        font-weight: 700;
        background: #fff;
    }
    .main-menu .c-menu-main-menu > li:nth-child(3) ul.sub-menu:after {
        content: "";
        background: rgba(0, 0, 0, 0.18);
        height: 0.5px;
        width: 98%;
        right: 1%;
        position: absolute;
    }
    .main-menu .sub-menu .more > span {
        display: none;
    }
    .main-menu .sub-menu ul.overflow {
        padding: 0;
    }
    .main-menu-wrapper {
        position: absolute;
        left: 0;
        top: 54px;
        width: 100%;
        margin: 0 auto;
        display: none;
        background: #fff;
        -webkit-box-shadow: 0 7px 7px rgba(55, 55, 55, 0.09);
        box-shadow: 0 7px 7px rgba(55, 55, 55, 0.09);
    }
    .main-menu-wrapper .c-menu-main-menu {
        width: 100%;
        padding: 18px 0 0;
        margin: 0;
    }
    .main-menu-wrapper .c-menu-main-menu > li > a {
        display: block;
        white-space: nowrap;
        padding: 18px 42px;
        height: 58px;
        text-decoration: none;
        background-color: #41633b;
        color: #fff;
        font-family: Philosopher;
        font-size: 1.125rem;
        font-weight: 700;
        position: relative;
        margin: -1px 0 0;
        border-top-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }
    .main-menu-wrapper .c-menu-main-menu > li > a:after,
    .main-menu-wrapper .c-menu-main-menu > li > a:before {
        content: "";
        background-color: #41633b;
        position: absolute;
        mask-mode: alpha;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: cover;
        mask-size: cover;
    }
    .main-menu-wrapper .c-menu-main-menu > li > a:before {
        left: 0;
        top: 31px;
        width: 36px;
        height: 51px;
        z-index: 1;
        -webkit-mask-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjVweCIgaGVpZ2h0PSI1MHB4IiB2aWV3Qm94PSIwIDAgMjUgNTAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+QXJ0Ym9hcmQgQ29weTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEtbWlzZS1ham91ci0tIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iQXJ0Ym9hcmQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMDAwMDAwLCAtMjA1LjAwMDAwMCkiIGZpbGw9IiMyOTY4QjIiPgogICAgICAgICAgICA8cGF0aCBkPSJNMjAsMTY1IEwzNTUsMTY1IEMzNjYuMDQ1Njk1LDE2NSAzNzUsMTU2LjA0NTY5NSAzNzUsMTQ1IEwzNzUsMTQ1IEwzNzUsMTQ1IEwzNzUsMjAzIEMzNzUsMjE0LjA0NTY5NSAzNjYuMDQ1Njk1LDIyMyAzNTUsMjIzIEwyMCwyMjMgQzguOTU0MzA1LDIyMyAtMS4zNTI3MDc1ZS0xNSwyMzEuOTU0MzA1IDAsMjQzIEwwLDI0MyBMMCwyNDMgTDAsMTg1IEMtMS4zNTI3MDc1ZS0xNSwxNzMuOTU0MzA1IDguOTU0MzA1LDE2NSAyMCwxNjUgWiIgaWQ9IlJlY3RhbmdsZS1Db3B5LTEyIj48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=);
        mask-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjVweCIgaGVpZ2h0PSI1MHB4IiB2aWV3Qm94PSIwIDAgMjUgNTAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+QXJ0Ym9hcmQgQ29weTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEtbWlzZS1ham91ci0tIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iQXJ0Ym9hcmQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMDAwMDAwLCAtMjA1LjAwMDAwMCkiIGZpbGw9IiMyOTY4QjIiPgogICAgICAgICAgICA8cGF0aCBkPSJNMjAsMTY1IEwzNTUsMTY1IEMzNjYuMDQ1Njk1LDE2NSAzNzUsMTU2LjA0NTY5NSAzNzUsMTQ1IEwzNzUsMTQ1IEwzNzUsMTQ1IEwzNzUsMjAzIEMzNzUsMjE0LjA0NTY5NSAzNjYuMDQ1Njk1LDIyMyAzNTUsMjIzIEwyMCwyMjMgQzguOTU0MzA1LDIyMyAtMS4zNTI3MDc1ZS0xNSwyMzEuOTU0MzA1IDAsMjQzIEwwLDI0MyBMMCwyNDMgTDAsMTg1IEMtMS4zNTI3MDc1ZS0xNSwxNzMuOTU0MzA1IDguOTU0MzA1LDE2NSAyMCwxNjUgWiIgaWQ9IlJlY3RhbmdsZS1Db3B5LTEyIj48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=);
    }
    .main-menu-wrapper .c-menu-main-menu > li > a:after {
        right: 0;
        bottom: 40px;
        width: 25px;
        height: 50px;
        -webkit-mask-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjVweCIgaGVpZ2h0PSI1MHB4IiB2aWV3Qm94PSIwIDAgMjUgNTAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+QXJ0Ym9hcmQ8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xLW1pc2UtYWpvdXItLSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkFydGJvYXJkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzUwLjAwMDAwMCwgLTEzMy4wMDAwMDApIiBmaWxsPSIjMjk2OEIyIj4KICAgICAgICAgICAgPHBhdGggZD0iTTIwLDE2NSBMMzU1LDE2NSBDMzY2LjA0NTY5NSwxNjUgMzc1LDE1Ni4wNDU2OTUgMzc1LDE0NSBMMzc1LDE0NSBMMzc1LDE0NSBMMzc1LDIwMyBDMzc1LDIxNC4wNDU2OTUgMzY2LjA0NTY5NSwyMjMgMzU1LDIyMyBMMjAsMjIzIEM4Ljk1NDMwNSwyMjMgLTEuMzUyNzA3NWUtMTUsMjMxLjk1NDMwNSAwLDI0MyBMMCwyNDMgTDAsMjQzIEwwLDE4NSBDLTEuMzUyNzA3NWUtMTUsMTczLjk1NDMwNSA4Ljk1NDMwNSwxNjUgMjAsMTY1IFoiIGlkPSJSZWN0YW5nbGUtQ29weS0xMiI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
        mask-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjVweCIgaGVpZ2h0PSI1MHB4IiB2aWV3Qm94PSIwIDAgMjUgNTAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+QXJ0Ym9hcmQ8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xLW1pc2UtYWpvdXItLSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkFydGJvYXJkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzUwLjAwMDAwMCwgLTEzMy4wMDAwMDApIiBmaWxsPSIjMjk2OEIyIj4KICAgICAgICAgICAgPHBhdGggZD0iTTIwLDE2NSBMMzU1LDE2NSBDMzY2LjA0NTY5NSwxNjUgMzc1LDE1Ni4wNDU2OTUgMzc1LDE0NSBMMzc1LDE0NSBMMzc1LDE0NSBMMzc1LDIwMyBDMzc1LDIxNC4wNDU2OTUgMzY2LjA0NTY5NSwyMjMgMzU1LDIyMyBMMjAsMjIzIEM4Ljk1NDMwNSwyMjMgLTEuMzUyNzA3NWUtMTUsMjMxLjk1NDMwNSAwLDI0MyBMMCwyNDMgTDAsMjQzIEwwLDE4NSBDLTEuMzUyNzA3NWUtMTUsMTczLjk1NDMwNSA4Ljk1NDMwNSwxNjUgMjAsMTY1IFoiIGlkPSJSZWN0YW5nbGUtQ29weS0xMiI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
    }
    .main-menu-wrapper .c-menu-main-menu > li:nth-child(1) > a,
    .main-menu-wrapper .c-menu-main-menu > li:nth-child(1) > a:after,
    .main-menu-wrapper .c-menu-main-menu > li:nth-child(1) > a:before {
        background-color: #76a82f;
    }
    .main-menu-wrapper .c-menu-main-menu > li:nth-child(2) > a,
    .main-menu-wrapper .c-menu-main-menu > li:nth-child(2) > a:after,
    .main-menu-wrapper .c-menu-main-menu > li:nth-child(2) > a:before {
        background-color: #2968b2;
    }
    .main-menu-wrapper .c-menu-main-menu > li > ul {
        display: none;
        background: #fff;
        font-size: 14px;
        padding: 0;
    }
    .main-menu-wrapper .c-menu-main-menu > li > ul li {
        border-bottom: 1px solid #fff;
    }
    .main-menu-wrapper .c-menu-main-menu > li > ul li:last-child {
        padding-bottom: 0;
    }
    .main-menu-wrapper .c-menu-main-menu > li > ul a {
        display: block;
        white-space: nowrap;
        text-decoration: none;
        font-family: Philosopher;
        font-size: 15px;
        font-weight: 700;
        color: #373737;
        -webkit-transition: all 0.25s ease;
        transition: all 0.25s ease;
        padding: 13px 46px;
    }
    .main-menu-wrapper .c-menu-main-menu > li > ul a :focus {
        outline: 0;
    }
    .main-menu-wrapper .t-menu-menu-top-header li {
        padding: 20px 25px;
    }
    .main-menu-wrapper .t-menu-menu-top-header li a {
        font-family: Philosopher;
        cursor: pointer;
        display: block;
        white-space: nowrap;
        color: #999;
        background-color: #fff;
        font-size: 18px;
        font-weight: 700;
        position: relative;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
    }
    .main-menu-wrapper .satoripop-language-item {
        display: block;
    }
    .light-menu {
        display: none !important;
    }
    .business-theme .main-menu-wrapper .c-menu-main-menu > li:nth-child(2) > ul,
    .particuliers-theme
        .main-menu-wrapper
        .c-menu-main-menu
        > li:nth-child(1)
        > ul {
        display: block;
    }
    body:not(.particuliers-theme):not(.business-theme)
        .main-menu-wrapper
        .c-menu-main-menu
        > li:nth-child(3)
        > ul {
        display: block;
    }
    .satoripop-language-item li a {
        color: #999;
        font-weight: 600;
        padding: 10px 42px 20px;
    }
    .link-before {
        display: none;
    }
}

@media (max-width: 991.98px) {
    .main-menu-wrapper .t-menu-menu-top-header li:nth-child(4) {
        display: block;
    }
}
.main-menu-wrapper .satoripop-language-item li {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1px solid rgba(151, 151, 151, 0.29);
    order: 1px solid rgba(151, 151, 151, 0.29);
    background-color: #fff;
}
.hamburger-menu {
    display: none;
    text-transform: uppercase;
}
@media (max-width: 991.98px) {
    .logo-web,
    .tQfdq,
    .light-menu,
    #mobile-main-menu-wrapper,
    .yamm,
    .navbar-nav,
    .imthfs {
        display: none !important;
    }
    .hero-header {
        background-position: right !important;
    }
    .hamburger-menu {
        display: block;
        position: relative;
        text-align: center;
        width: 24px;
        height: 20px;
        margin-left: 20px;
        cursor: pointer;
    }
    .hamburger-menu .burger-1,
    .hamburger-menu .burger-2,
    .hamburger-menu .burger-3 {
        display: block;
        position: absolute;
        width: 24px;
        height: 3px;
        border-radius: 30px;
        background: #76a82f;
        -webkit-transition: 0.2s ease;
        transition: 0.2s ease;
    }
    .hamburger-menu .burger-1 {
        top: 0;
    }
    .hamburger-menu .burger-2 {
        top: 8px;
    }
    .hamburger-menu .burger-3 {
        top: 16px;
    }
    .hamburger-menu .burger-1.open,
    .hamburger-menu .burger-2.open,
    .hamburger-menu .burger-3.open {
        -webkit-transition: 0.2s ease;
        transition: 0.2s ease;
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }
    .hamburger-menu .burger-1.open {
        top: 8px;
        width: 24px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    .hamburger-menu .burger-2.open {
        opacity: 0;
    }
    .hamburger-menu .burger-3.open {
        top: 8px;
        width: 24px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
}
.top-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 140px;
    position: absolute;
    width: 100%;
    z-index: -1;
    top: 0;
    left: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
@media (max-width: 991.98px) {
    #faq {
        /* right: 148px !important;
        top: 121px !important; */
        font-size: 38px !important;
    }
    .video-react .video-react-big-play-button {
        display: none !important;
    }
    .Digital {
        padding: 20px 20px !important;
        min-height: 83vh !important;
    }
    .D-description {
        line-height: 24px !important;
    }
    .D-description p {
        font-size: 14px !important;
        margin-top: 20px;
        font-family: Georgia, "Times New Roman", Times, serif;
    }
    #Dgt {
        width: 100% !important;
    }
    #Dg_title {
        right: 0px !important;
        top: 116px !important;
        font-size: 24px;
        text-align: center;
    }
    /* #partners_title {
        right: 127px !important;
        top: 186px !important;
    } */
    .partners-Banner {
        flex-direction: column;
    }
    .Question {
        text-align: center;
    }
    #msidi {
        right: 43px !important;
    }
    #drgry {
        width: 151px !important;
    }
    #rnomnot {
        left: 16px !important;
    }
    .top-header {
        height: 55px;
        position: sticky;
        top: 0px;
        background: #fff;
        margin-bottom: -20px;
        z-index: 999;
    }
    .top-header .site-logo {
        margin: 2px 8px 0 20px;
    }
    .top-header.fixed-header {
        position: fixed;
        background: #fff;
        -webkit-animation: smoothScroll 1s forwards;
        animation: smoothScroll 1s forwards;
    }
    .top-header.fixed-header .bloc-logo:before {
        display: none;
    }
    .top-header.fixed-header .site-logo {
        margin-top: 0;
    }
    .swiper-pointer-events {
        border-top-right-radius: 0px;
    }
}
