@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');

* {
  margin: 0;
  padding: 0;
}

.projects {
  font-size: 13px;
  height: 80vh;
  background: url('https://ortec-group.com/wp-content/uploads/2017/12/marches-oil-gas.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-family: 'PT Sans', sans-serif;
  background-color: rgba(0, 0, 0, .2);
  background-blend-mode: overlay;
}

.container-glass,
.container-glass::before,
.container-glass::after {
  border-radius: 7px;
  background-color: rgba(255, 255, 255, .2);
  backdrop-filter: blur(5px);
  box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.08);
}
.container-glass {
  position: relative;
  padding: 2rem 1.5rem;
  width: 80%;
  max-width: 280px;
}
.container-glass::before,
.container-glass::after {
  content: '';
  position: absolute;
}
.container-glass::before {
  height: 80px;
  width: 80px;
  top: -10px;
  left: -40px;
  z-index: -1;
}
.container-glass::after {
  height: 40px;
  width: 40px;
  bottom: 20px;
  right: -10px;
  z-index: 2;
}

.container-glass .img {
  width: 100%;
  border-radius: 7px;
}

.container-glass .text {
  font-size: 1.01rem;
  color: #DDD;
  margin: 2rem 0;
  text-align: justify;
}
