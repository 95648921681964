.team-bg {
  width: 100%;
  float: left;
  padding: 0;
  margin: 0px;
  text-align: center;
  background: url("../images/bg-team.png");
  background-attachment: fixed;
  background-clip: initial;
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position: 100% 0; */
  background-position: center;
  z-index: 1;
}
.team-title {
  font-size: 40px;
  color: #fff !important;
  font-weight: 600;
}
